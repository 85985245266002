import React from "react"
import CookieConsent from "react-cookie-consent";

import "./Layout.scss"

const Layout = ({ children }) => {
  return (
    <>
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        enableDeclineButton
        containerClasses="cookie-banner"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "14px", FontFace: '"Open Sans", sans-serif;' }}
        cookieName="gatsby-gdpr-google-analytics"
        expires={150}
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>
      <div className="wrapper">{children}</div>
    </>
  )
}

export default Layout
