exports.components = {
  "component---src-pages-blog-article-template-js": () => import("./../../../src/pages/blog/article-template.js" /* webpackChunkName: "component---src-pages-blog-article-template-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-tag-index-js": () => import("./../../../src/pages/blog/tag/index.js" /* webpackChunkName: "component---src-pages-blog-tag-index-js" */),
  "component---src-pages-cookie-js": () => import("./../../../src/pages/cookie.js" /* webpackChunkName: "component---src-pages-cookie-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-preorder-js": () => import("./../../../src/pages/preorder.js" /* webpackChunkName: "component---src-pages-preorder-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-tc-js": () => import("./../../../src/pages/tc.js" /* webpackChunkName: "component---src-pages-tc-js" */)
}

