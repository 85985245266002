const InititalState = {
  language: "en-US",
}

const SWITCH_LANGUAGE = "SWITCH_LANGUAGE"

const result = (state = InititalState, action) => {
  switch (action.type) {
    case SWITCH_LANGUAGE: {
      return {
        ...state,
        language: action.language.language,
      }
    }
    default: {
      return state
    }
  }
}

export default result

export const setLanguage = language => ({
  type: "SWITCH_LANGUAGE",
  language,
})
